import React, {useEffect, useState} from 'react';
import {offersLanguages} from "@utils/languageList";
import OfferType from "@pages/CreateCareerPage/components/offerType";
import CustomSelect from "@components/CustomSelect";
import Loader from "@components/Loader";
import ModalCustom from "@components/ModalCustom";
import {useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {GET_COMPANY_PROFILES_V2, GET_COMPETENCIES, GET_OFFERS_CITIES, GET_OFFERS_SPECIALIZATIONS} from "@api/endpoints";
import {
    createOffer,
    getCityInfo, loadAllCompanies,
    loadCompanies,
    loadOfferById,
    removeOffer,
    updateOffer
} from "@store/asyncActions/offers";
import {toast} from "react-toastify";
import {displayDateYYYY_MM_ddThh_mm, secondDateBigger} from "@utils/dateFormat";
import {Checkbox, FormControlLabel} from "@mui/material";
import CitiesListSelect from "@components/CitiesListSelect";

const CreateOrEdit = ({offer, setOffer}) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const [withoutDate, setWithoutDate] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);

    // operations modal content
    const [deleteContent, setDeleteContent] = useState({});
    const [salaryByAgreement, setSalaryByAgreement] = useState(false);
    const user = useSelector((state) => state.user.user);
    const offers = useSelector(state => state.offers);
    const [activeType, setActiveType] = useState(offers.offerTypes[0]);
    const [activeJobTime, setActiveJobTime] = useState();//Формат работы
    const [activeJobType, setActiveJobType] = useState();//Тип занятости
    const [authorId, setAuthorId] = useState(null);
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [city, setCity] = useState("");
    const [specialization, setSpecialization] = useState("");
    const [competency, setCompetency] = useState([]);
    const [salary, setSalary] = useState({from: "", to: ""});
    const [date, setDate] = useState({from: null, to: null});

    const getRequest = useVariables().requestOptionsGET;
    const [competenciesFilters, setCompetenciesFilters] = useState({size:100});
    const {data:competencies} = usePagination(GET_COMPETENCIES,getRequest,"","",competenciesFilters);
    const [companies, setCompanies] = useState([]);
    const [limit, setLimit] = useState({limit:1000});
    const requestOptionsGET  = useVariables().requestOptionsGET;
    //const companies = usePagination(GET_COMPANY_PROFILES_V2,requestOptionsGET,'companyId,desc',null,{limit: limit.limit},true).data



    const requestOptionsOutgoings =
        useVariables().requestOptionsGET;


    const cities = usePagination(GET_OFFERS_CITIES, requestOptionsOutgoings, "");
    const specializations = usePagination(GET_OFFERS_SPECIALIZATIONS, requestOptionsOutgoings, "");

    const [error, setError] = useState([]);


    useEffect(() => {
       /* fetch(GET_COMPETENCIES(), requestOptionsOutgoings)
            .then((response) => response.json())
            .then((json) => {
                setCompetencies(json)
            })
            .catch(() => console.log("Произошла ошибка"))*/
        loadAllCompanies(user,1000).then((json) => setCompanies(json.content))
    }, [])

    useEffect(() => {
        if (params.careerId >= 0) {
            loadOfferById(user, params.careerId).then((json) => {
                setActiveType(json.offerType);
                setAuthorId(json.authorId)
                setCity(json.cityId)
                setCompetency(json.competenceIds)
                setSalary({from: json.minSalary, to: json.maxSalary})
                setDate({
                    from: json.startDate ? displayDateYYYY_MM_ddThh_mm(json.startDate) : null,
                    to: json.endDate ? displayDateYYYY_MM_ddThh_mm(json.endDate) : null
                })
                setActiveJobType(json.formatJobType)
                setActiveJobTime(json.timeJobType)
                setSalaryByAgreement(json.salaryByAgreement)
                setSpecialization(json.specializationId)
                setName(json.name)
                setDescription(json.description)
                setOffer(json)
            }).catch((e) => {
                console.log(e)
                toast.error("Ошибка при загрузке данных")
            })
        }
    }, [params.careerId])

    const validate = () => {
        let errors = [];
        if (!authorId && !searchParams.get("orgId")) {
            errors.push({message: "Выберите организацию", field: "type"});
        }
        if (!activeType) {
            errors.push({message: "Выберите тип предложения", field: "type"});
        }
        if (!name) {
            errors.push({message: "Введите название предложения", field: "name"});
        }
        if (!activeJobTime) {
            errors.push({message: "Выберите формат работы", field: "time"});//Формат работы
        }
        if (!activeJobType) {
            errors.push({message: "Выберите тип занятости", field: "type"});//Тип занятости
        }
        /*if(!specialization){
            errors.push({message: "Выберите специализацию",field: "specialization"});//Специализация
        }*/
        let salary_ = {from: parseInt(salary.from), to: parseInt(salary.to)};
        if (activeType === "VACANCY" && !salaryByAgreement) {
            if ((!salary_.from && !salary_.to)) {
                errors.push({message: "Введите зарплату", field: "salary"});//Зарплата
            } else if (salary_.from >= salary_.to) {
                errors.push({message: "Зарплата ОТ должна быть больше чем ДО", field: "salary"});//Зарплата
            }
        }


        if ((activeType === "INTERNSHIP" || activeType === "PRACTICE") && !withoutDate) {
            if(date.from && date.to && !secondDateBigger(date.from, date.to))
                errors.push({message: "Дата окончания должна быть больше чем начала", field: "date"});
            else if(!date.from &&!date.to)
                errors.push({message: "Введите дату отработки", field: "date"});//Зарплата

        }

        errors.map(x => {
            toast.error(x.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        })
        return errors.length === 0;
    }
    const updateOffer_ = (col, val) => {
        let of = {...offer};
        of[col] = val;
        setOffer({...of});
    }

    const save = (type) => {
        if (validate()) {
            setLoading(true);
            let data = {
                "offerType": activeType,
                "cityId": city,
                "authorId": authorId,
                "offerStatus": type,
                "specializationId": specialization,
                "name": name,
                "description": description,
                "timeJobType": activeJobTime,
                "salaryByAgreement": salaryByAgreement,
                "formatJobType": activeJobType,
                "minSalary": parseInt(salary.from),
                "maxSalary": parseInt(salary.to),
                "additional": "",
                "startDate": date.from?.toString() || null,
                "endDate": date.to?.toString() || null,
                "competenceIds": competency
            };


            if (params.careerId >= 0) {
                updateOffer(user, data, params.careerId)
                    .then((json) => {
                        updateOffer_("offerStatus",json.offerStatus);
                    }).catch(() => {
                    toast.error("Произошла ошибка", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                let createOfferFromOrg = searchParams.get("orgId");
                if(createOfferFromOrg){
                    data.authorId = createOfferFromOrg
                }
                createOffer(user, data).then((json) => {
                    setLoading(false);
                    if (json.offerId >= 0) {
                        toast.success(json.offerStatus === "DRAFT" ? "Опубликовано как черновик" : "Предложение успешно создано", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        if(createOfferFromOrg){
                            navigate(-1, {state: {previousLocationPathname: "/"}});

                        }else
                            navigate(`/admin/edit-career/${json.offerId}`, {state: {previousLocationPathname: "/admin/careers"}});
                    } else {
                        toast.error("Произошла ошибка", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                })
                    .catch(() => console.log("Произошла ошибка")).finally(() => {
                    setLoading(false)
                })


            }

        }
    }

    const findInitValue = (list, column, id, nameColumn = "name", many = false) => {

        if (!Array.isArray(list))
            return null;
        let doc = many ? list.filter(x => id.includes(x[column])) : list.find(x => x[column] === id);

        if (doc)
            if (many) {
                return doc.map(x => {
                    return {
                        label: x[nameColumn],
                        value: x[column]
                    }
                })
            } else
                return {
                    label: doc[nameColumn],
                    value: doc[column]
                }
        else
            return null;
    }
    const initCopany = () =>{
        if(companies && companies.length && authorId){
            let doc = companies.find(x=>x.companyId === authorId);
            return {
                label: doc.name,
                value: doc.companyId
            }
        }
        return null;
    }
    const descriptionPlaceholder = (e) => {
        switch (activeType) {
            case "VACANCY":
                return "Описание вакансии";
            case  "INTERNSHIP":
                return "Описание стажировки";
            case  "PRACTICE":
                return "Описание практики";
            default:
                return "Описание";
        }

    }
    const loadDefaultCity = async (cityId) =>{
        let data = await getCityInfo(user,cityId);
        return {label: data.city, value: data.cityId};
    }

    const openRemoveModal = () => {
        setDeleteContent({name: offer.name, id: offer.offerId})
        setDeleteModalActive(true)
    }

    const deleteCatalogItem = () => {
        removeOffer(user, offer.offerId);
        navigate(-1);
    }

    return (
        <>
            <div className="create-block">
                {offer && offer.offerStatus &&
                    <div className="create-blk">
                        <div className="create-status">
                            <span className="title">Статус</span>
                            <span
                                className={`status ${offer.offerStatus === "ACTUAL" ? "type-green" : ""}`}>{offersLanguages.status[offer.offerStatus]}</span>
                        </div>
                    </div>
                }

                {!params.careerId &&
                    <OfferType activeType={activeType} setActiveType={setActiveType}/>
                }
                {!searchParams.get("orgId") &&
                    <div className="create-blk">
                        <span className="title">Организация</span>
                        <CustomSelect
                            isDisabled={params.careerId >= 0}
                            placeholder={"Организация"}
                            refValue={companies && initCopany()}
                            onChange={(e) => {
                                setAuthorId(e)
                            }}
                            isMulti={false}
                            options={companies.map((comp) => {
                                return {label: comp.name ? comp.name : "Без названия", value: comp.companyId}
                            })}
                        />
                    </div>

                }


                <div className="create-blk">
                    <span className="title">Название</span>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text" placeholder="Название вакансии (макс. 150 символов)"/>

                </div>

                <div className="create-blk">
                    <span className="title">Регион</span>
                    <CitiesListSelect
                        loadDefault={params.careerId && city ? ()=>loadDefaultCity(city) : null}
                        refVal={city} isMulti={false} callback={e=>setCity(e)}/>
                    {/*<CustomSelect
                        placeholder={"Город/область"}
                        refValue={params.careerId && city && cities.data && findInitValue(cities.data, "cityId", city, "city")}
                        onChange={(e) => {
                            setCity(e)
                        }}
                        isMulti={false}
                        options={cities.data.map((city) => {
                            return {label: city.city, value: city.cityId}
                        })}
                    />*/}
                </div>
                {(activeType === "INTERNSHIP" || activeType === "PRACTICE") &&
                    <div className="create-blk">
                        <div className="create-blk">
                            <div className="input-block">
                                <div className="input-blk">
                                    <label>Дата начала</label>
                                    <input
                                        value={date.from}
                                        onChange={(e) => setDate({...date, from: e.target.value})}
                                        className="form_field"
                                        name="startDate"
                                        type="datetime-local"
                                        disabled={withoutDate}
                                        placeholder="Введите дату начала"
                                    />
                                </div>
                                <div className="input-blk">
                                    <label>Дата окончания</label>
                                    <input
                                        value={date.to}
                                        onChange={(e) => setDate({...date, to: e.target.value})}
                                        className="form_field"
                                        disabled={withoutDate}
                                        name="endDate"
                                        type="datetime-local"
                                        placeholder="Введите дату окончания"
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{userSelect: "none"}}>
                            <FormControlLabel onChange={() => setWithoutDate(prev => !prev)}
                                              control={<Checkbox checked={withoutDate}/>} label={
                                <span>Без даты</span>
                            }/>
                        </div>
                    </div>
                }
                {(activeType === "VACANCY") &&
                    <div className="create-blk">
                        <div className="create-blk">
                            <span className="title">Заработная плата</span>
                            <div className="input-block">
                                <div className="input-blk">
                                    <label htmlFor="From">От</label>
                                    <input disabled={salaryByAgreement} type="text" value={salary.from}
                                           onChange={(e) => {
                                               setSalary({...salary, from: e.target.value})
                                           }} placeholder="0" id="From"/>
                                </div>
                                <div className="input-blk">
                                    <label htmlFor="To">До</label>
                                    <input disabled={salaryByAgreement} type="text"
                                           value={salary.to} onChange={(e) => {
                                        setSalary({...salary, to: e.target.value})
                                    }}
                                           placeholder="0" id="To"/>
                                </div>
                            </div>
                        </div>
                        <div style={{userSelect: "none"}}>
                            <FormControlLabel onChange={() => setSalaryByAgreement(prev => !prev)}
                                              control={<Checkbox checked={salaryByAgreement}/>} label={
                                <span>По результатам собеседования</span>
                            }/>
                        </div>
                    </div>
                }
                <div className="create-blk">
                    <span className="title">Выбор характеристик предложения</span>
                    <div className="create-choise-blk">
                        <span className="title">Направление</span>
                        <CustomSelect
                            parentStyle={{flex: "1"}}
                            refValue={params.careerId && specialization && specializations.data &&
                                findInitValue(specializations.data, "specializationId", specialization, "specializationName")}
                            placeholder={"Специализация"}
                            onChange={(e) => {
                                setSpecialization(e)
                            }}
                            isMulti={false}
                            options={specializations.data.map((spec) => {
                                return {label: spec.specializationName, value: spec.specializationId}
                            })}
                        />
                    </div>
                    <div className="create-choise-blk">
                        <span className="title">Компетенции</span>
                        <CustomSelect
                            parentStyle={{flex: "1"}}
                            placeholder={"Компетенции"}
                            refValue={params.careerId && competency && competencies &&
                                findInitValue(competencies, "competenceId", competency, "name", true)}
                            onChange={(e) => {
                                setCompetency(e)
                            }}
                            isMulti={true}
                            options={competencies?.map((comp) => {
                                return {label: comp.name, value: comp.competenceId}
                            })}
                        />
                    </div>
                    <div className="create-choise-blk">
                        <span className="title">Формат работы</span>
                        <CustomSelect
                            parentStyle={{flex: 1}}
                            placeholder={"Полная занятость"}
                            refValue={params.careerId && activeJobTime && offers.timeJobTypes &&
                                {label: offersLanguages.timeJobTypes[activeJobTime], value: activeJobTime}}
                            onChange={(e) => {
                                setActiveJobTime(e)
                            }}

                            isMulti={false}
                            options={offers.timeJobTypes.map((jobType, index) => {
                                return {label: offersLanguages.timeJobTypes[jobType], value: jobType}
                            })}
                        />

                    </div>
                    <div className="create-choise-blk">
                        <span className="title">Тип занятости</span>
                        <CustomSelect
                            parentStyle={{flex: 1}}
                            placeholder={"Полный день"}
                            refValue={params.careerId && activeJobType && offers.formatJobTypes &&
                                {label: offersLanguages.formatJobTypes[activeJobType], value: activeJobType}}
                            onChange={(e) => {
                                setActiveJobType(e)
                            }}
                            isMulti={false}

                            options={offers.formatJobTypes.map((jobType, index) => {
                                return {label: offersLanguages.formatJobTypes[jobType], value: jobType}
                            })}
                        />
                    </div>
                </div>
                <div className="create-blk">
                    <span className="title">Описание</span>
                    <textarea  placeholder={descriptionPlaceholder()} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                <div className="table-btn">
                    {loading ? <Loader/> :
                        <>
                            {params.careerId &&
                                <button onClick={openRemoveModal} className="btn btn-grey">Удалить</button>
                            }
                            <button onClick={() => save("DRAFT")} className="btn btn-black">Опубликовать как черновик
                            </button>
                            <button onClick={() => save("ACTUAL")} className="btn btn-blue">
                                {params.careerId >= 0 ?
                                    (offer?.offerStatus !== "DRAFT" ? "Сохранить" : "Опубликовать")
                                    : "Опубликовать"}
                            </button>
                        </>
                    }

                </div>
            </div>
            <ModalCustom
                title="Удаление элемента"
                active={deleteModalActive}
                setActive={setDeleteModalActive}
            >
                {deleteContent && (
                    <div className="delete_modal">
                        <p>Вы уверены, что хотите удалить "{deleteContent.name}"?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setDeleteModalActive(false)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={() => deleteCatalogItem()}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                )}
            </ModalCustom>
        </>
    )

}

export default React.memo(CreateOrEdit);