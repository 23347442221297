import React, {useEffect, useMemo, useState} from 'react';
import Competences from "@pages/ProfilePage/components/ProfileStudent/Indicators/Competences";
import Achievements from "@pages/ProfilePage/components/ProfileStudent/Indicators/Achievements";
import Progress from "@pages/ProfilePage/components/ProfileStudent/Indicators/Progress";
import Dpos from "@pages/ProfilePage/components/ProfileStudent/Indicators/Dpos";
import Rsvs from "@pages/ProfilePage/components/ProfileStudent/Indicators/Rsvs";
import CustomTabs from "@components/CustomTabs";
import {Link, NavLink} from "react-router-dom";
import {PDFDownloadLink, usePDF} from "@react-pdf/renderer";
import StudentPdf from "@pages/StudentPdf/StudentPdf";
import {useSelector} from "react-redux";
import {useProfileStudent} from "@hooks/useProfileStudent";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {DOWNLOAD_FILE} from "@api/endpoints";
import {IsMyProfileStudent, IsStudent} from "@hooks/useAuth";



const Indicators = ({className,studentId}) => {
    const user = useSelector((state) => state.user.user);
    const isMyProfile = IsMyProfileStudent(studentId)
    const { studentData } = useProfileStudent(studentId ? studentId: user.id);
    const [activeTab,setActiveTab]= useState(0);
    const tabs = [
        {id:0,title:"Компетенции"},
        {id:1,title:"Достижения"},
        {id:2,title:"Области деятельности"},
        {id:3,title:"ДПО"},
        {id:4,title:"Компетенции в рамках РСВ"}
    ]
    const tabsElements = [
        <Competences studentData={studentData}/>,
        <Achievements studentData={studentData}/>,
        <Progress studentData={studentData}/>,
        <Dpos studentData={studentData}/>,
        <Rsvs studentData={studentData}/>
    ]

    /*const [instance, updateInstance] = usePDF({ document: <StudentPdf user={user} img={img} avatar={avatar} studentData={studentData}/>});*/

    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    const pdfComponent = <StudentPdf ref={targetRef}/>

    return(
        <div className={`indicator-page ${className}`}>
            <div className="indicator-block">
                <div className="indicator-blk blks">

                    <div className="line_block align_center">
                        <h1>Показатели <span></span></h1>
                        {isMyProfile &&
                            <NavLink
                                to={"/profile/pdf"}
                                className="to_page"
                            >
                                Скачать PDF
                                <span className="i-up_right"></span>
                            </NavLink>
                        }

                    </div>
                    <div className="tabs">
                        <CustomTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} className={"d-flex tabsmenu"}/>

                        <div className="tab-content">
                            {tabsElements[activeTab]}
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}
export default Indicators;