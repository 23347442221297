import React from "react"
import {NavLink} from "react-router-dom";
import {offersLanguages} from "@utils/languageList";


const CareerBlock = ({item}) => {
    return (
        <div key={item.offerId} className={`col-block ${item.offerStatus === "ARCHIVE" ?  "block-archive" : ""}`}>
            <NavLink key={item.offerId} to={`/profile/view-career/${item.offerId}`} end>
                <div className={`offer-content-block blks  ${item.offerStatus === "ARCHIVE" ?  "offer-archive" : ""}`}>
                    <div className="offer-title vacancy-title">
                        <p className="title">{item?.company?.name}</p>
                        <span>{offersLanguages.type[item?.offerType]}</span>

                        {/*{item && item.offerStatus &&
                            <div className="create-status">
                                    <span
                                        className={`status ${item.offerStatus === "ACTUAL" ? "type-green" : ""}`}>{offersLanguages.status[item.offerStatus]}</span>
                            </div>
                        }*/}
                    </div>
                    <span className="name">{item.name}</span>

                    <h3>{item.desc}</h3>
                    <div className="details">
                        <ul className="unstyled">
                            <li className="place">{offersLanguages.timeJobTypes[item.timeJobType]}</li>
                            <li className="date">{offersLanguages.formatJobTypes[item.formatJobType]}</li>
                        </ul>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default CareerBlock