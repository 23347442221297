import React, {useEffect, useRef, useState} from 'react';
import CustomSelect from "@components/CustomSelect";
import {usePreviewFile} from "@hooks/usePreviewFile";
import {IsAdmin, IsCompany} from "@hooks/useAuth";
import {ADMIN_COMPANY, DOWNLOAD_FILE, GET_COMPANY_PROFILES_V2, SHOP} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {toast} from "react-toastify";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import {usePagination} from "@hooks/usePagination";
import ShopActions from "@store/asyncActions/ShopActions";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Information = ({productId,setActiveTab}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const previousLocationPathname = useLocation().state?.previousLocationPathname

    const requestOptionsGET = useVariables().requestOptionsGET;

    const [info, setInfo] = useState(null)
    useEffect(() => {
        fetch(ADMIN_COMPANY.GET_INFORMATION, requestOptionsGET).then(res => res.json()).then(res => setInfo(res))
    }, []);
    const [product, setProduct] = useState(null)
    useEffect(() => {
        if (productId) {
            fetch(SHOP.GET_PRODUCT_BY_ID(productId), requestOptionsGET).then(res => res.json()).then(res => {
                setSelectedCompanies(res.company.companyId)
                setProduct(res)
            })
        }
    }, [productId])
    const {
        selectedFile: selectedAvatar,
        setSelectedFile: setSelectedAvatar,
        handleFileChange: handleImageChange,
    } = usePreviewFile(
        null,
        "image.*",
    );
    const formRef = useRef()
    const statuses = [
        {label: "Опубликован", value: "ACTUAL"},
        {label: "Черновик", value: "DRAFT"},
        {label: "Архив", value: "ARCHIVE"},
        ]
    const isAdmin = IsAdmin();
    const img = useDownloadFile(DOWNLOAD_FILE, product?.imgId).file;
    const [avatarCropModalActive, setAvatarCropModalActive] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState();
    const [status, setStatus] = useState();
    const isCompany = IsCompany();
    const fileIdRef = useRef();
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [filters,setFilters] = useState({size:1000})
    const {createProduct, uploadAvatar, updateProduct} = ShopActions();
    const {data: companies} = usePagination(GET_COMPANY_PROFILES_V2, requestOptionsGET, "","",filters);


    const submitForm = (e) => {
        e.preventDefault()
        const formData = new FormData(formRef.current)
        const formObject = Object.fromEntries(formData.entries())
        formObject.productStatus = JSON.parse(formObject.productStatus)?.value
        formObject.companyId = isCompany ? user.id : (formObject.companyId ? JSON.parse(formObject.companyId)?.value : null);
        if (!formObject.name || (!isCompany && !formObject.companyId) || !formObject.productStatus) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_CENTER,
            });
            return false;
        }
        if (!productId)
            createProduct(formObject).then(res => res.json()).then(res => {
                if (res.error || res.status === "Error") {
                    toast.error(res.message[0], {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    return false;
                } else {
                    toast.success("Товар добавлен", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    if (selectedAvatar) {
                        uploadAvatar(selectedAvatar, res.productId)
                    }
                    if (!isCompany)
                        navigate(`/admin/shop/edit/${res.productId}`, {state: {previousLocationPathname: previousLocationPathname}});
                    else
                        navigate(`/profile/shop/edit/${res.productId}`, {state: {previousLocationPathname: previousLocationPathname}});
                }
            }).catch(() => {
                toast.error("Ошибка при добавлении товара", {
                    position: toast.POSITION.TOP_CENTER
                })
            });
        else updateProduct(productId * 1, formObject).then(res => res.json())
            .then(res => {
                if (res.error || res.status === "Error") {
                    toast.error(res.message[0], {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    return false;
                } else {
                    toast.success("Товар сохранен", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    if (selectedAvatar) {
                        uploadAvatar(selectedAvatar, res.productId)
                    }
                }
            }).catch(() => {
                toast.error("Ошибка при редактировании товара", {
                    position: toast.POSITION.TOP_CENTER
                })
            });

        return false;
    }

    const handleAvatarFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match("image.*")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedAvatarImage({url: e.target.result});
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (selectedAvatarImage) {
            setAvatarCropModalActive(true);
        }
    }, [selectedAvatarImage]);

    const [croppedImage, setCroppedImage] = useState();
    useEffect(() => {
        if (croppedImage) {
            setSelectedAvatar(croppedImage);
        }
    }, [croppedImage]);

    const findCompany = (companyId) => {
        const cmp = companies.find(x => x.companyId === selectedCompanies)
        console.log("FS,",cmp,companies)
        if (cmp)
            return {label: cmp.name ? cmp.name : cmp.longName, value: cmp.companyId}
    }
    if(productId && !product)
        return "";
    else
    return (
        <div className={"add-tovar active"}>
            <div className="add-photo product">
                {((selectedAvatar) || (img && product?.imgId)) &&
                    <>
                        <img src={selectedAvatar ? selectedAvatar : img} alt="avatar"/>

                    </>
                }
                <span>Выберите файл или перетащите сюда</span>
                <input onChange={handleAvatarFileChange} type="file" title="Выберите файл или перетащите сюда"/>
                <input type={"hidden"} ref={fileIdRef}/>
            </div>
            <div className="tovar-info">
                <form ref={formRef} onSubmit={submitForm} className="search-form">
                    <div className="flex">
                        <div className="search-blk create-blk">
                            <label htmlFor="TovarName">Название товара</label>
                            <input defaultValue={product?.name} name={"name"} type="text" placeholder="Футболка"
                                   id="TovarName"/>
                        </div>
                        {!isCompany &&
                            <div className="search-blk create-blk">
                                <label htmlFor="Institute">Организация</label>
                                <CustomSelect
                                    placeholder={"Выберите организацию"}
                                    refValue={selectedCompanies && companies && findCompany(selectedCompanies)}
                                    onChange={(value) => {
                                        setSelectedCompanies(value)
                                    }}
                                    name="companyId"
                                    options={companies.map((item) => {
                                        return {label: item.name ? item.name : item.longName, value: item.companyId}
                                    })}
                                />
                            </div>
                        }

                        <div className="search-blk create-blk">
                            <label htmlFor="Status">Статус</label>
                            <CustomSelect
                                placeholder={"Статус"}
                                onChange={(value) => setStatus(value)}
                                refValue={status && {
                                    value: status,
                                    label: statuses.find(item => item.value === status).label
                                }}
                                name="productStatus"
                                options={statuses}
                            />
                        </div>
                        <div className="search-blk create-blk search-blk-text">
                            <label htmlFor="Descript">Описание</label>
                            <textarea defaultValue={product?.description} name="description" id="Descript"
                                      placeholder="Описание"></textarea>
                        </div>
                    </div>
                    {product &&
                        <div style={{marginTop: 20}} className="tovar-info-blk">
                            <div className="flex">
                                <h3>Аукцион</h3>
                                <NavLink state={
                                    {
                                        previousLocationPathname: !isAdmin ?
                                            `/profile/shop/edit/${productId}` :
                                            `/admin/shop/edit/${productId}`
                                    }
                                }
                                         to={isAdmin ? `/admin/shop/admin-view/${productId}`:`/profile/shop/${productId}`} className="btn btn-arrow">Посмотреть</NavLink>
                            </div>
                        </div>
                    }
                    <div className="table-btn student-btn">
                        <button className="btn btn-grey">Отменить</button>
                        <button type="submit" className="btn btn-blue" value={""}>Сохранить</button>
                    </div>
                </form>

            </div>
            <ModalCustom
                title="Подготовка Аватара"
                active={avatarCropModalActive}
                setActive={setAvatarCropModalActive}
            >
                <CropModalContent
                    image={selectedAvatarImage ? selectedAvatarImage.url : undefined}
                    onClose={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setAvatarCropModalActive(false);
                    }}
                />
            </ModalCustom>
        </div>
    )

}
export default Information;