import spbpolytech from "../assets/svg/partners/spbpolytech.svg";
import prioritet2030 from "../assets/svg/partners/prioritet2030.svg";
import rosmolodezh from "../assets/svg/partners/rosmolodezh.svg";
import plandesyatiletie from "../assets/svg/partners/plandesyatiletie.svg";
import silnieidei from "../assets/svg/partners/silnieidei.svg";
import sberbank from "../assets/svg/partners/sberbank.svg";
import rosatom from "../assets/svg/partners/rosatom.svg";

export const PARTNERS_MAP = {
  polytech: {
    logo: spbpolytech,
    link: "https://vk.com/polytech_petra",
  },
  prioritet: {
    logo: prioritet2030,
    link: "http://strategy.spbstu.ru/",
  },
  rosmol: {
    logo: rosmolodezh,
    link: "http://fadm.gov.ru/directions/grant/",
  },
  plandesyatiletie: {
    logo: plandesyatiletie,
    link: "http://xn--80aa3ak5a.xn--p1ai/",
  },
  silnieidei: {
    logo: silnieidei,
    link: "http://xn--d1ach8g.xn--c1aenmdblfega.xn--p1ai/",
  },
  sberbank: {
    logo: sberbank,
    link: "http://www.sberbank.ru/",
  },
  rosatom: {
    logo: rosatom,
    link: "http://www.rosatom.ru/",
  },
};

export const PARTNERS = [
  PARTNERS_MAP.polytech,
  PARTNERS_MAP.prioritet,
  PARTNERS_MAP.rosmol,
  PARTNERS_MAP.plandesyatiletie,
  PARTNERS_MAP.silnieidei,
  PARTNERS_MAP.sberbank,
  PARTNERS_MAP.rosatom,
];
